@font-face {
    font-family: 'Space Grotesk';
    src: local('Space Grotesk Medium'), local('SpaceGrotesk-Medium'),
    url('/fonts/SpaceGrotesk-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Space Grotesk';
    src: local('Space Grotesk Bold'), local('SpaceGrotesk-Bold'), url('/fonts/SpaceGrotesk-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Neue Machina';
    src: local('Neue Machina Light'), local('NeueMachina-Light'), url('/fonts/NeueMachina-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Space Grotesk';
    src: local('Space Grotesk SemiBold'), local('SpaceGrotesk-SemiBold'),
    url('/fonts/SpaceGrotesk-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Space Grotesk';
    src: local('Space Grotesk Regular'), local('SpaceGrotesk-Regular'),
    url('/fonts/SpaceGrotesk-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Neue Machina';
    src: local('Neue Machina Ultrabold'), local('NeueMachina-Ultrabold'),
    url('/fonts/NeueMachina-Ultrabold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Space Grotesk';
    src: local('Space Grotesk Light'), local('SpaceGrotesk-Light'), url('/fonts/SpaceGrotesk-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Neue Machina';
    src: local('Neue Machina Regular'), local('NeueMachina-Regular'),
    url('/fonts/NeueMachina-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

:root {
    --color-primary: black;
    --color-secondary: #f1f5f8;
    --color-complimentary: #b8eee4;
    --color-button-bg: rgb(191, 155, 248);
    --color-button-text: #fff;
    --color-white-text: #fff;
    --color-labels: #6d7b86;
    --color-text: #212529
}


input:focus-visible {
    border-color: var(--color-primary) !important;
    outline: none;
}

body, input, button {
    font-family: "Space Grotesk", sans-serif;
    font-weight: 400;
    font-variant-numeric: lining-nums;
    -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Neue Machina", serif;
}

body, h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
}

.wrapper {
    max-width: 1200px;
    margin: 0 auto;
}
